$(function () {
  const spMax = 640;

  //==========
  //汎用function
  //==========
  // スクロール禁止状態にする
  function htmlFix() {
    var st = $(window).scrollTop() * -1;
    $("html").addClass("is-fixed");
    $("body").css("top", st);
  }

  //スクロール禁止状態を解除する
  function htmlScroll() {
    var st = parseFloat($("body").css("top")) * -1;
    $("html").removeClass("is-fixed").scrollTop(st);
    $("body").removeAttr("style");
  }

  //オーバーレイ表示
  function overlayOn(location, mode) {
    if ($(".overlay").length < 1) {
      location.after('<div class="overlay js-overlay"></div>');

      if (!(mode == "none")) {
        htmlFix();
      }

      $(".overlay").animate(
        {
          opacity: 1
        },
        200
      );
    }
  }

  //オーバーレイ非表示
  function overlayOff() {
    $(".overlay").css("pointer-events", "none");
    $(".overlay").fadeOut("", function () {
      $(".overlay").remove();
    });
    htmlScroll();
  }

  //==========
  //ツールチップ
  //==========
  var tooltipTrigger = $(".js-tooltip-trigger");

  //ツールチップボタンをクリックした時の処理
  tooltipTrigger.on("click", function () {
    var target = $(this).closest(".js-tooltip").find(".js-tooltip-panel");

    if (target.hasClass("is-open")) {
      target.removeClass("is-open");
    } else {
      target.addClass("is-open");
    }
  });

  //画面のどこかをクリックした時に、ツールチップの吹き出しを閉じる
  var tooltipPanel = $(".js-tooltip-panel");

  $(document).on("click", function (e) {
    tooltipPanel.each(function () {
      // 表示されている吹き出しがあり、かつクリックした箇所がツールチップボタンじゃない時
      // prettier-ignore
      if (
        $(this).hasClass("is-open") &&
        !$(e.target).hasClass("js-tooltip-trigger")
      ) {
        $(this).removeClass("is-open");
      }
    });
  });

  //==========
  //続きを見る　４行以上は続きを見るボタンを表示しない（ボタンクリック時）
  //==========
  var moreText = $(".js-more-gradient-contents");
  const lineNum = 4; //4行以上
  var lineHeight = parseFloat(moreText.css("line-height"));
  var textNewHeight = lineHeight * lineNum;

  $(".js-more-gradient-trigger").on("click", function (e) {
    e.preventDefault();

    var contents = $(this).closest(".js-more-gradient").children(".js-more-gradient-contents");
    var triggerBox = $(this).parents(".js-more-gradient-trigger-box");
    var contentsTrueHeight = contents[0].scrollHeight;

    if (triggerBox.hasClass("is-open")) {
      triggerBox.removeClass("is-open");
      $(this).text("続きを見る");
      contents.css("height", textNewHeight);
    } else {
      triggerBox.addClass("is-open");
      $(this).text("閉じる");
      contents.css("height", contentsTrueHeight);
    }
  });

  //==========
  //グロナビ
  //==========
  if (spMax >= $(window).width()) {
    var navList = $(".js-nav-list");

    //ハンバーガーをクリックしたとき
    $(".js-nav-trigger").on("click", function (e) {
      e.preventDefault();

      var trigger = $(this);

      if (trigger.hasClass("is-open")) {
        closeNav("nav-trigger", trigger);
      } else {
        trigger.addClass("is-open");
        navList.addClass("is-shown");
        overlayOn($(".header"));
      }
    });

    //オーバーレイクリックで閉じる
    $(document).on("click", ".js-overlay", function () {
      closeNav("overlay");
    });

    //ページ内リンククリックで閉じる
    $(".js-nav-jump").on("click", function () {
      closeNav("jump", $(this));
    });

    //グロナビを閉じる関数
    function closeNav(closeTriggerName, closeTrigger) {
      var trigger;

      if (closeTriggerName == "nav-trigger") {
        trigger = closeTrigger;
      } else if (closeTriggerName == "overlay") {
        trigger = $(".js-overlay").prev().find(".js-nav-trigger");
      } else if (closeTriggerName == "jump") {
        trigger = closeTrigger.closest(".header-nav").find(".js-nav-trigger");
      }

      trigger.removeClass("is-open");
      navList.removeClass("is-shown");
      overlayOff();
    }
  }

  //==========
  //トグル
  //==========
  $(".js-toggle-trigger").click(function (e) {
    e.preventDefault();

    var trigger = $(this);

    // prettier-ignore
    var panel =
      typeof trigger.attr("href") !== "undefined" &&
      trigger.attr("href").length > 0
        ? $(trigger.attr("href"))
        : trigger.next(".js-toggle-panel");

    if (trigger.hasClass("is-open")) {
      trigger.removeClass("is-open");
      panel.fadeOut(200);
      if (trigger.parents(".result-action__confirm").length) {
        trigger.text("リストを確認");
      }
    } else {
      trigger.addClass("is-open");
      panel.fadeIn(200);
      if (trigger.parents(".result-action__confirm").length) {
        trigger.text("リストを閉じる");
      }
    }
  });

  //==========
  //モーダル
  //==========

  $(".js-modal-close").on("click", function () {
    $(this).parents(".js-modal").removeClass("is-open");
    $(this).parents(".js-modal").next(".js-overlay").remove();
  });

  //==========
  //モーダル　横移動
  //==========
  $(window).on("scroll", function () {
    $(".js-modal").css("left", -$(window).scrollLeft());
  });

  //-----
  // タブ
  //-----
  $(".js-tab-trigger").click(function (e) {
    e.preventDefault();
    let p = $(this).closest(".js-tab");
    let t = $(this).attr("href");

    if ($(this).hasClass("is-active")) {
      //is-activeを保持している場合対象を表示
      $(t).show();
    } else {
      //is-activeを付け外し
      $(p).find(".js-tab-trigger").removeClass("is-active");
      $(this).addClass("is-active");

      //すべてのパネルを非表示にした上で対象を表示
      $(p).find(".js-tab-panel").hide();
      $(t).stop().fadeIn(300);
    }
  });

  // .is-activeのパネルはページ読み込み時に表示
  $(".js-tab-trigger.is-active").trigger("click");

  // ====================
  // もっと見る 4件以上のリスト
  // ====================
  if (spMax >= $(window).width()) {
    // 画面読み込み時、５個目以降のアイテムを隠す
    $(".js-more-list").each(function () {
      $(this).children("li:gt(3)").hide();
    });

    var moreButton = $(".js-more-button");

    moreButton.click(function () {
      // prettier-ignore
      var listOver = $(this)
        .parents(".js-more")
        .children($(".js-more-list"))
        .children("li:gt(3)");

      //.js-more-buttonが.is-openを持っていない時、隠されたリストを開き、.js-more-buttonのテキストを変更する
      //.is-openを持っている時は逆の処理
      if ($(this).hasClass("is-open")) {
        listOver.hide();
        $(this).removeClass("is-open");
        $(this).html('もっと見る<i class="ico-toggle"></i>');
      } else {
        listOver.show();
        $(this).addClass("is-open");
        $(this).html('閉じる<i class="ico-toggle"></i>');
      }
    });
  }

  //==========
  //スムーズスクロール
  //==========
  $(".js-scroll").click(function () {
    var speed = 200;
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? "html" : href);
    var position = target.offset().top;

    //スクロール先がタブの場合に、スクロールして目的のタブを開く処理
    if ($(this).data("scroll") == "tab") {
      // prettier-ignore
      target.closest(".js-tab").find(".js-tab-trigger").each(function () {
          //目的のタブパネルと同じidをhrefに持つタブボタンを見つけ、目的のタブパネルを開く
          if ($(this).attr("href") == "#" + target.attr("id")) {
            $(this).trigger("click");
          }
        });

      //目的地はタブボタンの高さ分をマイナスした距離
      position = target.offset().top - $(this).height();
    }

    $("html, body").animate({ scrollTop: position }, speed, "swing");
    return false;
  });
});

//テキストの高さを正確に図るため、loadで処理
$(window).on("load", function () {
  const spMax = 640;

  //==========
  //続きを見る　４行以上は続きを見るボタンを表示しない （画面ロード時）
  //==========
  var moreText = $(".js-more-gradient-contents");
  const lineNum = 4; //4行以上
  var lineHeight = parseFloat(moreText.css("line-height"));
  var textNewHeight = lineHeight * lineNum;

  if (moreText.length > 0) {
    moreText.each(function () {
      var text = $(this);
      var moreButton = text.closest(".js-more-gradient").find(".js-more-gradient-trigger-box");
      var textTrueHeight = text[0].scrollHeight;

      //data-more属性がonlySPであり、画面幅がスマホサイズより大きい場合は続きを見るボタンを隠す。
      if (text.data("more") == "onlySP" && $(window).width() > spMax) {
        text.css("height", textTrueHeight);
        moreButton.hide();
      } else if (textTrueHeight > textNewHeight) {
        text.css("height", textNewHeight);
        moreButton.show();
      } else {
        text.css("height", textTrueHeight);
        moreButton.hide();
      }
    });
  }
});
